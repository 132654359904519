<template>
  <v-dialog
    :value="dialog"
    max-width="600px"
    transition="fade-transition"
    @click:outside="$emit('update:dialog', false)"
  >
    <v-card
      class="py-4"
    >
      <v-row
        class="px-6"
        no-gutters
      >
        <v-btn
          icon
          color="error"
          @click="$emit('update:dialog', false)"
        >
          <v-icon>mdi-close</v-icon>
          Fechar
        </v-btn>
        <v-card-title>
          <span class="text-h5">Escolha o Bucket</span>
        </v-card-title>
      </v-row>

      <v-row
        justify="center"
        class="px-6"
        no-gutters
      >
        <v-text-field
          v-model="search"
          clearable
          placeholder="Buscar"
        />
      </v-row>

      <v-row
        justify="center"
        no-gutters
      >
        <div
          v-if="!bucketList.length"
          class="text-h3"
        >
          Carregando Buckets...
        </div>
        <v-list
          v-else
          flat
        >
          <v-subheader>BUCKETS AWS S3</v-subheader>
          <template
            v-for="(item, i) in bucketList"
          >
            <v-list-item
              v-show="!search || item.Name.includes(search)"
              :key="i"
              link
              @click="selectBucket(item)"
            >
              <v-list-item-icon>
                <v-icon>mdi-bucket</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                <v-list-item-title v-text="item.Name" />
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
  // import { mapGetters } from 'vuex'
  import axiosSYNC from '../../../../../axios-SYNC'

  export default {
    name: 'ChangeBucket',
    model: {
      prop: 'dialog',
      event: 'update:dialog',
    },
    props: {
      dialog: {
        type: Boolean,
        required: true,
      },
    },
    data: () => {
      return {
        bucketList: [],
        search: '',
      }
    },
    mounted () {
      this.getBuckets()
    },
    methods: {
      async getBuckets () {
        try {
          const response = await axiosSYNC.get('/list/buckets')
          this.bucketList = response.data.Buckets
        } catch (err) {
          console.error('Erro buscando buckets', err)
        }
      },
      selectBucket (bucket) {
        this.$store.commit('files/SET_BUCKET', bucket.Name)
        this.$emit('update:dialog', false)
        this.$emit('reload')
      },
    },
  }
</script>
